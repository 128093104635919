<template>
  <prona-wizard-step class="wizard-step-membership-category" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div class="membership-selection" :class="classList">
      <p-row>
        <p-col
          v-for="membership in memberships"
          :key="membership.id"
          xs12
          md6
          lg4
        >
          <p-radio-field v-model="wizardData.membershipTemplateId" :true-value="membership.id" name="membership-category" :label="membership.caption" @change="onMembershipSelect($event, membership)"></p-radio-field>
          <p class="caption-1">
            {{ $t('pronatura.widget.membershipwizard.amount_description', { fee: membership.minimumAnnualFee }) }}
            <span v-if="membership.isGiftMembershipAvailable">
              <br>{{ $t('pronatura.widget.membershipwizard.gift_available') }}
            </span>
          </p>
        </p-col>
      </p-row>
    </div>
  </prona-wizard-step>
</template>

<script lang="ts">
  import GetMembershipTemplatesRequestViewModel from '@/src/services/v1/viewModel/request/WidgetMembershipTemplates/GetMembershipTemplatesRequestViewModel';
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import MembershipTemplateResourceViewModel from '@/src/services/v1/viewModel/resource/MembershipTemplateResourceViewModel';
  import { PropType } from 'vue';
  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';

  export default mixins(validatable).extend({
    name: 'WizardStepMembershipCategory',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
      memberships: [] as MembershipTemplateResourceViewModel[],
    }),
    watch: {
      'wizardData.membershipTemplateId': {
        handler() {
          if (this._.isSet(this.wizardData.membershipTemplateId)) {
            this.validationError = undefined;
          }
        },
      },
    },
    computed: {
      classList(): Record<string, boolean> {
        return {
          'input-error': this._.isSet(this.validationError),
        };
      },
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.value ?? {};
        },
        set(value: CreateMembershipRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      // clear WidgetAnalyticsData
      window.glitWidget = {};
      this.$sessionStorage.remove('widget-analytics-window');

      this.isLoading = true;
      try {
        const req = new GetMembershipTemplatesRequestViewModel();
        const result = await this.$service.api.widgetMembershipTemplates.getMembershipTemplates(req);
        this.memberships = result.items;
      } catch (error: any) {
        this.error = error;
        this.$log.error(error);
      }
      this.isLoading = false;
    },
    methods: {
      validate(): boolean {
        if (!this._.isSet(this.wizardData.membershipTemplateId)) {
          this.validationError = this.$t('pronatura.widget.membershipwizard.membership_error');
          return false;
        }
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.proceedFunction!();
        }
      },
      async onMembershipSelect(id: any, membership: MembershipTemplateResourceViewModel) {
        await this.clearProgressFunction!();
        this.wizardData.membershipTemplateId = membership.id!;
        this.wizardData.isGiftMembership = false;
        this.$set(this.wizardData, 'chosenMembershipTemplate', membership);
      },
    },
  });
</script>
