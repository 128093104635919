// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateMembershipRequestModel from '../../model/create-membership-request-model';
import CreateMembershipRequestDTO from '../dto/create-membership-request-dto';
import dtoSchema from '../schemas/create-membership-request-schema';
import ContactModel from '../../model/contact-model';
import RequestSummaryModel from '../../model/request-summary-model';

export default abstract class CreateMembershipRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateMembershipRequestDTO>) {
    super();
    if (data) {
      this.membershipTemplateId = data.membershipTemplateId;
      this.recruitingBusinessUnitNumber = data.recruitingBusinessUnitNumber ?? undefined;
      this.admissionReasonNumber = data.admissionReasonNumber ?? undefined;
      this.admissionMotivationReasonNumber = data.admissionMotivationReasonNumber ?? undefined;
      this.sourceCampaignNumber = data.sourceCampaignNumber ?? undefined;
      this.billingContact = data.billingContact ? ContactModel.toModel(data.billingContact) : undefined;
      this.memberContact = data.memberContact ? ContactModel.toModel(data.memberContact) : undefined;
      this.partnerContact = data.partnerContact ? ContactModel.toModel(data.partnerContact) : undefined;
      this.membershipAmount = data.membershipAmount;
      this.isGiftMembership = data.isGiftMembership;
      this.paymentMethod = data.paymentMethod;
      this.onlinePaymentTransactionId = data.onlinePaymentTransactionId ?? undefined;
      this.summary = data.summary ? RequestSummaryModel.toModel(data.summary) : undefined;
      this.acceptNewsletter = data.acceptNewsletter;
      this.acceptGdpr = data.acceptGdpr;
      this.languageIso2 = data.languageIso2 ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  membershipTemplateId?: string;
  /**
  * @type {int32}
  */
  recruitingBusinessUnitNumber?: number;
  /**
  * @type {int32}
  */
  admissionReasonNumber?: number;
  /**
  * @type {int32}
  */
  admissionMotivationReasonNumber?: number;
  /**
  * @type {int32}
  */
  sourceCampaignNumber?: number;
  /**
  */
  billingContact?: ContactModel;
  /**
  */
  memberContact?: ContactModel;
  /**
  */
  partnerContact?: ContactModel;
  /**
  * @type {double}
  */
  membershipAmount?: number;
  /**
  */
  isGiftMembership?: boolean;
  /**
  * @type {int32}
  */
  paymentMethod?: 1 | 2 | 3 | 6 | 7 | 8; // PaymentMethodDTO
  /**
  */
  onlinePaymentTransactionId?: string;
  /**
  */
  summary?: RequestSummaryModel;
  /**
  */
  acceptNewsletter?: boolean;
  /**
  */
  acceptGdpr?: boolean;
  /**
  */
  languageIso2?: string;

  static toModel(dto: DeepPartial<CreateMembershipRequestDTO>): CreateMembershipRequestModel;
  static toModel(dto: DeepPartial<CreateMembershipRequestDTO> | undefined | null): CreateMembershipRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateMembershipRequestDTO> | undefined | null): CreateMembershipRequestModel | undefined {
    return dto ? new CreateMembershipRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateMembershipRequestModel> | CreateMembershipRequestModel): CreateMembershipRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      membershipTemplateId: unwrapped.membershipTemplateId,
      recruitingBusinessUnitNumber: unwrapped.recruitingBusinessUnitNumber,
      admissionReasonNumber: unwrapped.admissionReasonNumber,
      admissionMotivationReasonNumber: unwrapped.admissionMotivationReasonNumber,
      sourceCampaignNumber: unwrapped.sourceCampaignNumber,
      billingContact: unwrapped.billingContact,
      memberContact: unwrapped.memberContact,
      partnerContact: unwrapped.partnerContact,
      membershipAmount: unwrapped.membershipAmount,
      isGiftMembership: unwrapped.isGiftMembership,
      paymentMethod: unwrapped.paymentMethod,
      onlinePaymentTransactionId: unwrapped.onlinePaymentTransactionId,
      summary: unwrapped.summary,
      acceptNewsletter: unwrapped.acceptNewsletter,
      acceptGdpr: unwrapped.acceptGdpr,
      languageIso2: unwrapped.languageIso2,
    } as CreateMembershipRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateMembershipRequestModel, can not map to CreateMembershipRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
