// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import AnimaturaTopicResourceViewModel from '../../../../viewModel/resource/AnimaturaTopicResourceViewModel';
import AnimaturaTopicResourceDTO from '../../dto/resource/AnimaturaTopicResourceDTO';

export default abstract class AnimaturaTopicResourceModel extends BaseModel<AnimaturaTopicResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int32}
  */
  get topicKey() { return this.dto.topicKey; }
  set topicKey(value) { this.dto.topicKey = value; }
  /**
  * @type {uuid}
  */
  get easydooId() { return this.dto.easydooId; }
  set easydooId(value) { this.dto.easydooId = value; }
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get isActive() { return this.dto.isActive; }
  set isActive(value) { this.dto.isActive = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.topickey);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.topicKey = value;
    this.dto.easydooId = qs.easydooid;
    this.dto.title = qs.title;
    this.dto.description = qs.description;
    value = qs.isActive === 'true' ? true : qs.isactive;
    value = qs.isActive === 'false' ? false : undefined;
    this.dto.isActive = value;
  }
}
