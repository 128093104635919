// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ContactRequestModel from '../../model/contact-request-model';
import ContactByIdRequestModel from '../../model/contact-by-id-request-model';
import ContactModel from '../../model/contact-model';

export default (service: Servicelayer) => ({
  /**  */
  async contact(request: ContactRequestModel, config?: RequestConfig) {
    const requestDTO = ContactRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-contact'] ?? 'Widget/OM/Contact/{customerNumber}/{contactNumber}';
    endpointPath = endpointPath.replace(/\{customerNumber\}/g, `${requestDTO.customerNumber}`);
    endpointPath = endpointPath.replace(/\{contactNumber\}/g, `${requestDTO.contactNumber}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ContactModel.toModel(dto));
  },
  /**  */
  async contactById(request: ContactByIdRequestModel, config?: RequestConfig) {
    const requestDTO = ContactByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-contact-by-id'] ?? 'Widget/OM/Contact/{objectId}';
    endpointPath = endpointPath.replace(/\{objectId\}/g, `${requestDTO.objectId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ContactModel.toModel(dto));
  },
});
