// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import CreateMembershipRequestModel from '../../model/create-membership-request-model';

export default (service: Servicelayer) => ({
  /**  */
  async createMembership(request: CreateMembershipRequestModel, config?: RequestConfig) {
    const requestDTO = CreateMembershipRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-membership'] ?? 'Widget/Memberships';
    const response = await service.post<any>(endpointPath, {
      body: {
        membershipTemplateId: requestDTO.membershipTemplateId,
        recruitingBusinessUnitNumber: requestDTO.recruitingBusinessUnitNumber,
        admissionReasonNumber: requestDTO.admissionReasonNumber,
        admissionMotivationReasonNumber: requestDTO.admissionMotivationReasonNumber,
        sourceCampaignNumber: requestDTO.sourceCampaignNumber,
        billingContact: requestDTO.billingContact,
        memberContact: requestDTO.memberContact,
        partnerContact: requestDTO.partnerContact,
        membershipAmount: requestDTO.membershipAmount,
        isGiftMembership: requestDTO.isGiftMembership,
        paymentMethod: requestDTO.paymentMethod,
        onlinePaymentTransactionId: requestDTO.onlinePaymentTransactionId,
        summary: requestDTO.summary,
        acceptNewsletter: requestDTO.acceptNewsletter,
        acceptGdpr: requestDTO.acceptGdpr,
        languageIso2: requestDTO.languageIso2,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
