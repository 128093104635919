// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import RequestSummarySchema from './request-summary-schema';

const schema = {
  $id: '/create-membership-request-schema',
  type: 'object',
  required: ['membershipTemplateId', 'membershipAmount'],
  properties: {
    membershipTemplateId: {
      type: 'string',
      format: 'uuid',
    },
    recruitingBusinessUnitNumber: {
      type: 'integer',
      nullable: true,
    },
    admissionReasonNumber: {
      type: 'integer',
      nullable: true,
    },
    admissionMotivationReasonNumber: {
      type: 'integer',
      nullable: true,
    },
    sourceCampaignNumber: {
      type: 'integer',
      nullable: true,
    },
    billingContact: {
      ...ContactSchema,
    },
    memberContact: {
      ...ContactSchema,
    },
    partnerContact: {
      ...ContactSchema,
    },
    membershipAmount: {
      type: 'number',
    },
    isGiftMembership: {
      type: 'boolean',
    },
    paymentMethod: {
      enum: [
        1,
        2,
        3,
        6,
        7,
        8,
      ],
    },
    onlinePaymentTransactionId: {
      type: 'string',
      nullable: true,
    },
    summary: {
      ...RequestSummarySchema,
    },
    acceptNewsletter: {
      type: 'boolean',
    },
    acceptGdpr: {
      type: 'boolean',
    },
    languageIso2: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
