<template>
  <prona-wizard-step class="wizard-step-membership-order-complete">
    <h4 class="text-color-success">
      <strong>{{ $t('pronatura.widget.membershipwizard.confirmation_title') }}</strong>
    </h4>
    <p class="mt-3" v-html="$t('pronatura.widget.membershipwizard.confirmation_lead')"></p>
    <p v-if="wizardData.isGiftMembership" class="mt-2" v-html="$t('pronatura.widget.membershipwizard.confirmation_giftmembershipcertificate')"></p>
    <p class="mt-2" v-html="$t('pronatura.widget.membershipwizard.confirmation_questions')"></p>
    <template #step-actions>
      <div></div>
    </template>
  </prona-wizard-step>
</template>

<script lang="ts">
  import { Widget } from '@/src/services/models/widgetAnalyticsWindow';
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardStepMembershipOrderComplete',
    props: {
      sentWizardData: { type: Object, default: undefined },
    },
    data: () => ({
    }),
    computed: {
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.sentWizardData ?? {};
        },
      },
    },
    async mounted() {
      // do not clear WidgetAnalyticsData: https://glittrsoftware.visualstudio.com/ProNatura.IL/_workitems/edit/9754
      // will be deleted on wizard entry
      window.glitWidget = this.$sessionStorage.get<Widget>('widget-analytics-window') ?? {};
    },
  });
</script>
