<template>
  <prona-wizard-step class="wizard-step-animatura-excursiondata">
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div class="pronatura-animatura-form">
      <p-row v-if="wizardData.isAdminMode">
        <p-col xs10 md6>
          <p-text-field v-model="contactSearchString" label="OM Number" clearable @clear="clearContacts()"></p-text-field>
        </p-col>
        <p-col xs2 md6 align-end>
          <p-button
            secondary
            :disabled="!searchContactIsValid(contactSearchString)"
            :loading="contactSearchSearching"
            @click="searchContact(contactSearchString)"
          >
            {{ $t('app.search') }}
          </p-button>
        </p-col>
      </p-row>
      <p-row v-if="wizardData.isAdminMode && contactSearchError !== undefined">
        <p-col>
          <p-alert value="true" type="error">
            {{ contactSearchError }}
          </p-alert>
        </p-col>
      </p-row>
      <p-row v-if="wizardData.isAdminMode">
        <p-col>
          <hr />
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md5>
          <p-select v-model="wizardData.teacherContact.address.salutation" :disabled="wizardData.teacherContact.contactId != undefined" :label="$t('core.address.salutation')" required :items="salutations"></p-select>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md6>
          <p-text-field v-model="wizardData.teacherContact.address.firstName" :disabled="wizardData.teacherContact.contactId != undefined" :label="$t('pronatura.widget.animaturawizard.teacher.firstName')" required></p-text-field>
        </p-col>
        <p-col xs12 md6>
          <p-text-field v-model="wizardData.teacherContact.address.lastName" :disabled="wizardData.teacherContact.contactId != undefined" :label="$t('pronatura.widget.animaturawizard.teacher.lastName')" required></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-phone-field v-model="wizardData.teacherContact.address.mobilePhoneNumber" :disabled="wizardData.teacherContact.contactId != undefined" :label="$t('pronatura.widget.animaturawizard.teacher.phonenumber')" class="animaturaTeacherPhoneField"></p-phone-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-text-field v-model="wizardData.teacherContact.address.eMail" :disabled="wizardData.teacherContact.contactId != undefined" :label="$t('pronatura.widget.animaturawizard.teacher.email')" required></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md5>
          <p-select v-model="wizardData.teacherContact.languageIso2" :disabled="wizardData.teacherContact.contactId != undefined" :label="$t('pronatura.widget.animaturawizard.teacher.correspondanceLanguage')" required :items="wizardData.translatedLanguages"></p-select>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-checkbox v-model="wizardData.acceptMarketing" :disabled="wizardData.teacherContact.contactId != undefined" :label="$t('pronatura.widget.animaturawizard.teacher.schoolMarketing')"></p-checkbox>
        </p-col>
      </p-row>
    </div>
  </prona-wizard-step>
</template>

<script lang="ts">
  import AnimaturaRegistrationRequestViewModel from '@/src/services/v1/viewModel/resource/AnimaturaRegistrationRequestViewModel';
  import AnimaturaTopicResourceViewModel from '@/src/services/v1/viewModel/resource/AnimaturaTopicResourceViewModel';
  import ContactAddressResourceViewModel from '@/src/services/v1/viewModel/resource/ContactAddressResourceViewModel';
  import ContactResourceViewModel from '@/src/services/v1/viewModel/resource/ContactResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardStepTeacherData',
    props: {
      value: { type: Object, default: undefined },
    },
    data: () => ({
      isLoading: false,
      correspondanceLanguage: [
        { caption: Vue.$t('core.page.language.de'), id: 'DE' },
        { caption: Vue.$t('core.page.language.fr'), id: 'FR' },
      ],
      salutations: [
        { caption: Vue.$t('core.address.salutationEnum1'), id: 1 },
        { caption: Vue.$t('core.address.salutationEnum2'), id: 2 },
        { caption: Vue.$t('core.address.salutationEnum3'), id: 3 },
      ],
      error: undefined as any,
      selectedMembership: undefined as undefined | AnimaturaTopicResourceViewModel,
      contactSearchError: undefined as string | undefined,
      contactSearchSearching: false as boolean,
      contactSearchString: '' as string,
    }),
    computed: {
      wizardData: {
        get(): AnimaturaRegistrationRequestViewModel {
          return this.value ?? {};
        },
        set(value: AnimaturaRegistrationRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      await this.wizardData.loadLanguages();
      if (!this._.isSet(this.wizardData.teacherContact.languageIso2)) {
        // Set default language, but only if it is valid (DE or FR)
        const language = this.$translation.get().toUpperCase();
        if (this.wizardData.translatedLanguages.some((l) => l.id?.toUpperCase() === language)) {
          this.wizardData.teacherContact.languageIso2 = language;
        }
      }
      if (this.wizardData.teacherContact.customerNumber !== undefined) {
        this.contactSearchString = `${this.wizardData.teacherContact.customerNumber}-${this.wizardData.teacherContact.contactNumber}`;
      }
      this.isLoading = false;
    },
    methods: {
      async clearContacts() {
        this.wizardData.teacherContact = new ContactResourceViewModel().fromModel({ address: new ContactAddressResourceViewModel().fromModel({}) });
        this.wizardData.teacherContact.contactId = null;
      },
      searchContactIsValid(searchstring: string) :boolean {
        return (/^\d{5,9}-\d{1,6}$/.test(searchstring));
      },
      async searchContact(searchstring: string) {
        this.contactSearchError = undefined;
        if (this.searchContactIsValid(searchstring)) {
          this.contactSearchSearching = true;
          const paramArray = searchstring.split('-');
          try {
            const contact = await this.$service.v1.api.widgetOM.contact(+paramArray[0], +paramArray[1]);
            if (contact !== null && contact?.customerNumber) {
              this.wizardData.teacherContact = contact;
              this.wizardData.teacherContact.address.mobilePhoneNumber = this.wizardData.teacherContact.address.mobilePhoneNumber?.replaceAll(' ', '');
              if (contact.parentContactId !== undefined && contact.parentContactId !== null) {
                const schoolContact = await this.$service.v1.api.widgetOM.contactById(contact.parentContactId);
                this.wizardData.schoolContact = schoolContact;
              } else {
                this.contactSearchError = this.$t('pronatura.widget.animaturawizard.om.contact.has.no.parent');
              }
            } else {
              this.contactSearchError = this.$t('pronatura.widget.animaturawizard.om.contact.not.found');
            }
          } catch (error:any) {
            this.contactSearchError = error?.data.result ?? error.message;
          } finally {
            this.contactSearchSearching = false;
          }
        } else {
          this.contactSearchError = this.$t('pronatura.widget.animaturawizard.om.wrong.format');
        }
        this.contactSearchSearching = false;
      },
    },
  });
</script>

<style lang="scss">
  .animaturaTeacherPhoneField {
    label{
      &::after {
        content: "*";
      }
    }
  }
</style>
