<template>
  <prona-wizard-step class="wizard-step-membership-online-payment">
    <p-row>
      <p-col>
        <p v-if="!error && !showPaymentFailedInfo">
          {{ $t("pronatura.widget.membershipwizard.payment_form_is_opening") }}
        </p>
        <prona-wallee-widget
          :show="showPaymentWidget"
          :initialize="showPaymentWidget"
          :transaction-id="wizardData.onlinePaymentTransactionId"
          :success-url="buildRedirectUrl('success')"
          :failed-url="buildRedirectUrl('failed')"
        ></prona-wallee-widget>
        <p-progress-linear v-if="isLoading" :indeterminate="true"></p-progress-linear>
        <p-request-alert v-model="error"></p-request-alert>
        <p-alert :value="showPaymentFailedInfo" type="error">
          <span v-html="$t('pronatura.widget.membershipwizard.payment_failed')"></span>
        </p-alert>
      </p-col>
    </p-row>
    <template #step-actions>
      <div class="mt-4 pb-2">
        <p-button
          v-if="!isLoading && !wizardData.onlinePaymentTransactionId"
          class="mr-3"
          secondary
          @click="backFunction"
        >
          <p-icon left>
            arrow_back
          </p-icon>
          {{ $t('core.app.back') }}
        </p-button>
        <p-button
          v-if="!isLoading && !wizardData.onlinePaymentTransactionId"
          @click="pay"
        >
          {{ $t('pronatura.widget.membershipwizard.pay') }}
          <p-icon right>
            credit_card
          </p-icon>
        </p-button>
      </div>
    </template>
  </prona-wizard-step>
</template>

<script lang="ts">
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import CreateTransactionRequestModel from '@/src/services/v2/model/create-transaction-request-model';
  import GetMembershipTemplateByIdRequestModel from '@/src/services/v2/model/get-membership-template-by-id-request-model';
  import OnlinePaymentContactAddressModel from '@/src/services/v2/model/online-payment-contact-address-model';
  import OnlinePaymentOrderItemModel from '@/src/services/v2/model/online-payment-order-item-model';
  import Vue, { PropType } from 'vue';

  const paymentStateQuery = 'paymentstate';
  type PaymentState = 'success' | 'failed';

  export default Vue.extend({
    name: 'WizardStepMembershipOnlinePayment',
    props: {
      value: { type: Object, default: undefined },
      saveFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      backFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      showPaymentFailedInfo: false,
      showPaymentWidget: false,
    }),
    computed: {
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.value ?? {};
        },
        set(value: CreateMembershipRequestViewModel) {
          this.$emit('input', value);
        },
      },
      shouldCreateTransaction: {
        get(): boolean {
          return this.$sessionStorage.get<boolean>('create-transaction') ?? false;
        },
        set(value: boolean | undefined): void {
          if (value === undefined) {
            this.$sessionStorage.remove('create-transaction');
            return;
          }
          this.$sessionStorage.set('create-transaction', value);
        },
      },
    },
    async beforeMount() {
      // Depending how this step was opened it has to be handle differntly
      const paymentState = this.$routerUtils.getQueryParamsFromUrl(window?.location?.href)[paymentStateQuery] as PaymentState | undefined;
      if (paymentState === 'success') {
        // Opened via: Redirect from wallee widget and payment was successful
        // Action: Go to confirmation step
        this.proceedFunction!();
        return;
      }
      if (paymentState === 'failed') {
        // Opened via: Redirect from wallee widget and payment failed
        // Action: Display that payment failed
        // Continuation: User can click back or pay button
        this.wizardData.onlinePaymentTransactionId = undefined;
        this.saveFunction!();
        this.$routerUtils.updateQueryParams([], { preserve: false });
        this.showPaymentFailedInfo = true;
        return;
      }
      if (!this.wizardData.onlinePaymentTransactionId && !this.shouldCreateTransaction) {
        // Opened via: Either page was refreshed while being on an earlier step and previously failed payment
        //             or user used breadcrum and previously failed payment
        // Action: Go back to summary page (only show wallee widget when user expects it)
        this.backFunction!();
        return;
      }
      await this.pay();
    },
    methods: {
      buildRedirectUrl(paymentState: PaymentState): string {
        return `${window.location.pathname}?${paymentStateQuery}=${paymentState}#${this.$appId}`;
      },
      async pay(): Promise<void> {
        if (this.wizardData.onlinePaymentTransactionId) {
          // Opened via: Page was refreshed while being on this payment step
          // Action: Show wallee widget again
          // Continuation: Wallee widget will redirect to this step
          this.error = undefined;
          this.showPaymentFailedInfo = false;
          this.showPaymentWidget = true;
          return;
        }
        // Opened via: User clicked on next button on summary step
        //             or clicked on pay button on this step after payment failed
        // Action: Create new transaction and show wallee widget
        // Continuation: Wallee widget will redirect to this step
        this.error = undefined;
        this.showPaymentFailedInfo = false;
        this.shouldCreateTransaction = undefined;
        this.wizardData.onlinePaymentTransactionId = await this.createTransaction();
        this.saveFunction!();
        this.showPaymentWidget = true;
      },
      async createTransaction(): Promise<string | undefined> {
        this.isLoading = true;
        let transactionId;
        try {
          const transaction = await this.convertToTransaction();
          const resp = await this.$service.v2.api.onlinePaymentTransaction.createTransaction(transaction);
          transactionId = resp.data;
        } catch (error) {
          this.error = error;
        }
        this.isLoading = false;
        return transactionId;
      },
      async convertToTransaction(): Promise<CreateTransactionRequestModel> {
        const membershipTemplateReq = new GetMembershipTemplateByIdRequestModel();
        membershipTemplateReq.id = this.wizardData.membershipTemplateId;
        const membershipTemplateResp = await this.$service.v2.api.widgetMembershipTemplates.getMembershipTemplateById(membershipTemplateReq);
        const membershipTemplate = membershipTemplateResp.data;

        const orderItem = new OnlinePaymentOrderItemModel();
        orderItem.productId = this.wizardData.membershipTemplateId;
        orderItem.name = membershipTemplate.caption;
        orderItem.sku = this.wizardData.membershipTemplateId;
        orderItem.unitPrice = this.wizardData.membershipAmount;
        orderItem.quantity = 1;

        const { address } = this.wizardData.billingContact;
        const billingAddress = new OnlinePaymentContactAddressModel();
        billingAddress.salutation = address.salutation?.toString();
        billingAddress.firstName = address.firstName ?? undefined;
        billingAddress.lastName = address.lastName ?? undefined;
        billingAddress.email = address.eMail ?? undefined;
        billingAddress.phoneNumber = address.phoneNumber ?? undefined;
        billingAddress.mobilePhoneNumber = address.mobilePhoneNumber ?? undefined;
        billingAddress.dateOfBirth = this.$date(address.dateOfBirth)?.toISOString();
        billingAddress.companyName = address.companyName ?? undefined;
        billingAddress.street = `${address.street ?? ''} ${address.houseNumber ?? ''}`.trim();
        billingAddress.countryISO = address.countryIso2 ?? undefined;
        billingAddress.zipCode = address.postalCode ?? undefined;
        billingAddress.city = address.city ?? undefined;

        const transaction = new CreateTransactionRequestModel();
        transaction.currency = 'CHF';
        transaction.language = this.wizardData.languageIso2 ?? undefined;
        transaction.billingAddress = billingAddress;
        transaction.shippingAddress = undefined;
        transaction.orderItems = [orderItem];
        transaction.isRecurring = true;

        return transaction;
      },
    },
  });
</script>
