// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAnimaturaTopicsRequestModel from '../../model/get-animatura-topics-request-model';
import GetAnimaturaTopicByIdRequestModel from '../../model/get-animatura-topic-by-id-request-model';
import AnimaturaTopicListResultModel from '../../model/animatura-topic-list-result-model';
import AnimaturaTopicModel from '../../model/animatura-topic-model';

export default (service: Servicelayer) => ({
  /**  */
  async getAnimaturaTopics(request: GetAnimaturaTopicsRequestModel, config?: RequestConfig) {
    const requestDTO = GetAnimaturaTopicsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-animatura-topics'] ?? 'Widget/Animatura/Topics';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AnimaturaTopicListResultModel.toModel(dto));
  },
  /**  */
  async getAnimaturaTopicById(request: GetAnimaturaTopicByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetAnimaturaTopicByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-animatura-topic-by-id'] ?? 'Widget/Animatura/Topics/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AnimaturaTopicModel.toModel(dto));
  },
});
