// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import PaymentMethodResourceViewModel from '../../../../viewModel/resource/PaymentMethodResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import CreateMembershipRequestViewModel from '../../../../viewModel/resource/CreateMembershipRequestViewModel';
import CreateMembershipRequestDTO from '../../dto/resource/CreateMembershipRequestDTO';

export default abstract class CreateMembershipRequestModel extends BaseModel<CreateMembershipRequestDTO> {
  /**
  * @type {uuid}
  */
  get membershipTemplateId() { return this.dto.membershipTemplateId; }
  set membershipTemplateId(value) { this.dto.membershipTemplateId = value; }
  /**
  * @type {int32}
  */
  get recruitingBusinessUnitNumber() { return this.dto.recruitingBusinessUnitNumber; }
  set recruitingBusinessUnitNumber(value) { this.dto.recruitingBusinessUnitNumber = value; }
  /**
  * @type {int32}
  */
  get admissionReasonNumber() { return this.dto.admissionReasonNumber; }
  set admissionReasonNumber(value) { this.dto.admissionReasonNumber = value; }
  /**
  * @type {int32}
  */
  get admissionMotivationReasonNumber() { return this.dto.admissionMotivationReasonNumber; }
  set admissionMotivationReasonNumber(value) { this.dto.admissionMotivationReasonNumber = value; }
  /**
  * @type {int32}
  */
  get sourceCampaignNumber() { return this.dto.sourceCampaignNumber; }
  set sourceCampaignNumber(value) { this.dto.sourceCampaignNumber = value; }
  /**
  */
  get billingContact() { return this.getObjectModel(() => this.dto.billingContact, ContactResourceViewModel)!; }
  set billingContact(value) { this.setObjectModel(() => this.dto.billingContact, value); }
  /**
  */
  get memberContact() { return this.getObjectModel(() => this.dto.memberContact, ContactResourceViewModel)!; }
  set memberContact(value) { this.setObjectModel(() => this.dto.memberContact, value); }
  /**
  */
  get partnerContact() { return this.getObjectModel(() => this.dto.partnerContact, ContactResourceViewModel)!; }
  set partnerContact(value) { this.setObjectModel(() => this.dto.partnerContact, value); }
  /**
  * @type {double}
  */
  get membershipAmount() { return this.dto.membershipAmount; }
  set membershipAmount(value) { this.dto.membershipAmount = value; }
  /**
  */
  get isGiftMembership() { return this.dto.isGiftMembership; }
  set isGiftMembership(value) { this.dto.isGiftMembership = value; }
  /**
  * @type {int32}
  */
  get paymentMethod() { return this.dto.paymentMethod; }
  set paymentMethod(value) { this.dto.paymentMethod = value; }
  /**
  */
  get onlinePaymentTransactionId() { return this.dto.onlinePaymentTransactionId; }
  set onlinePaymentTransactionId(value) { this.dto.onlinePaymentTransactionId = value; }
  /**
  */
  get summary() { return this.getObjectModel(() => this.dto.summary, RequestSummaryResourceViewModel)!; }
  set summary(value) { this.setObjectModel(() => this.dto.summary, value); }
  /**
  */
  get acceptNewsletter() { return this.dto.acceptNewsletter; }
  set acceptNewsletter(value) { this.dto.acceptNewsletter = value; }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.membershipTemplateId = qs.membershiptemplateid;
    value = Number.parseFloat(qs.recruitingbusinessunitnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.recruitingBusinessUnitNumber = value;
    value = Number.parseFloat(qs.admissionreasonnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.admissionReasonNumber = value;
    value = Number.parseFloat(qs.admissionmotivationreasonnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.admissionMotivationReasonNumber = value;
    value = Number.parseFloat(qs.sourcecampaignnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sourceCampaignNumber = value;
    value = Number.parseFloat(qs.membershipamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.membershipAmount = value;
    value = qs.isGiftMembership === 'true' ? true : qs.isgiftmembership;
    value = qs.isGiftMembership === 'false' ? false : undefined;
    this.dto.isGiftMembership = value;
    value = Number.parseFloat(qs.paymentmethod);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.paymentMethod = value;
    this.dto.onlinePaymentTransactionId = qs.onlinepaymenttransactionid;
    value = qs.acceptNewsletter === 'true' ? true : qs.acceptnewsletter;
    value = qs.acceptNewsletter === 'false' ? false : undefined;
    this.dto.acceptNewsletter = value;
    value = qs.acceptGdpr === 'true' ? true : qs.acceptgdpr;
    value = qs.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
    this.dto.languageIso2 = qs.languageiso2;
  }
}
