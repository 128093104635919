// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import BillingAddressResourceViewModel from '../../../../viewModel/resource/BillingAddressResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import AnimaturaRegistrationRequestViewModel from '../../../../viewModel/resource/AnimaturaRegistrationRequestViewModel';
import AnimaturaRegistrationRequestDTO from '../../dto/resource/AnimaturaRegistrationRequestDTO';

export default abstract class AnimaturaRegistrationRequestModel extends BaseModel<AnimaturaRegistrationRequestDTO> {
  /**
  */
  get isAdminMode() { return this.dto.isAdminMode; }
  set isAdminMode(value) { this.dto.isAdminMode = value; }
  /**
  * @type {int32}
  */
  get animationTopicKey() { return this.dto.animationTopicKey; }
  set animationTopicKey(value) { this.dto.animationTopicKey = value; }
  /**
  */
  get desiredDates() { return this.dto.desiredDates; }
  set desiredDates(value) { this.dto.desiredDates = value; }
  /**
  * @type {uuid}
  */
  get animationLanguageId() { return this.dto.animationLanguageId; }
  set animationLanguageId(value) { this.dto.animationLanguageId = value; }
  /**
  */
  get animationPlace() { return this.dto.animationPlace; }
  set animationPlace(value) { this.dto.animationPlace = value; }
  /**
  */
  get schoolClass() { return this.dto.schoolClass; }
  set schoolClass(value) { this.dto.schoolClass = value; }
  /**
  */
  get countParticipants() { return this.dto.countParticipants; }
  set countParticipants(value) { this.dto.countParticipants = value; }
  /**
  */
  get animationNotes() { return this.dto.animationNotes; }
  set animationNotes(value) { this.dto.animationNotes = value; }
  /**
  */
  get teacherContact() { return this.getObjectModel(() => this.dto.teacherContact, ContactResourceViewModel)!; }
  set teacherContact(value) { this.setObjectModel(() => this.dto.teacherContact, value); }
  /**
  */
  get acceptMarketing() { return this.dto.acceptMarketing; }
  set acceptMarketing(value) { this.dto.acceptMarketing = value; }
  /**
  */
  get schoolContact() { return this.getObjectModel(() => this.dto.schoolContact, ContactResourceViewModel)!; }
  set schoolContact(value) { this.setObjectModel(() => this.dto.schoolContact, value); }
  /**
  */
  get billingAddress() { return this.getObjectModel(() => this.dto.billingAddress, BillingAddressResourceViewModel)!; }
  set billingAddress(value) { this.setObjectModel(() => this.dto.billingAddress, value); }
  /**
  */
  get summary() { return this.getObjectModel(() => this.dto.summary, RequestSummaryResourceViewModel)!; }
  set summary(value) { this.setObjectModel(() => this.dto.summary, value); }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = qs.isAdminMode === 'true' ? true : qs.isadminmode;
    value = qs.isAdminMode === 'false' ? false : undefined;
    this.dto.isAdminMode = value;
    value = Number.parseFloat(qs.animationtopickey);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.animationTopicKey = value;
    this.dto.desiredDates = qs.desireddates;
    this.dto.animationLanguageId = qs.animationlanguageid;
    this.dto.animationPlace = qs.animationplace;
    this.dto.schoolClass = qs.schoolclass;
    this.dto.countParticipants = qs.countparticipants;
    this.dto.animationNotes = qs.animationnotes;
    value = qs.acceptMarketing === 'true' ? true : qs.acceptmarketing;
    value = qs.acceptMarketing === 'false' ? false : undefined;
    this.dto.acceptMarketing = value;
    value = qs.acceptGdpr === 'true' ? true : qs.acceptgdpr;
    value = qs.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
  }
}
